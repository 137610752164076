<template>
  <div id="header">
    <nav>
      <div>
        <a href="/">
          <img :src="heroSectionPaths['primary-logo']" alt="Boomm logo" />
        </a>
        <button @click="scrollToTop()">Download Now</button>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'HeaderPage',
  computed: {
    heroSectionPaths() {
      return {
        'primary-logo': require('@/assets/images/logos/primary.svg'),
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style scoped>
@import '@/assets/css/landingPage.css';
</style>
