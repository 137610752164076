<template>
  <div class="footer">
    <div class="container">
      <div class="boomm-information">
        <div class="row-one">
          <div class="boomm-logo">
            <img :src="footerImagesPaths.logo" alt="Boomm logo" />
          </div>
          <div class="boomm-description">
            A dynamic socio-investment learning platform for beginner investors.
          </div>
          <a
            href="https://maps.app.goo.gl/jcinmLnDh7DjcngW8"
            rel="noreferrer"
            target="_blank"
            class="address-link"
          >
            <div class="address-section">
              <div class="address-icon">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.05928 17.9541C1.66587 18.5436 2.27246 18.7345 3.46937 18.7515L16.5939 18.8033C16.7152 18.8033 16.8188 18.8211 16.8713 18.8728C16.9231 18.9246 16.9401 19.0289 16.9401 19.1502L16.9919 32.2755C17.0096 33.4717 17.2005 34.0783 17.8071 34.6849C18.6223 35.5171 19.7667 35.3787 20.6159 34.5465C21.0671 34.0953 21.4311 33.3504 21.7603 32.6565L35.0239 4.04985C35.717 2.59403 35.6305 1.51845 34.9203 0.824567C34.2257 0.114413 33.1508 0.0278624 31.695 0.721002L3.08766 13.9846C2.39378 14.3138 1.64886 14.6777 1.19762 15.129C0.365405 15.9782 0.227073 17.1219 1.05928 17.9541ZM4.57899 16.1173C4.56123 16.0655 4.596 16.0137 4.7003 15.9612L32.1455 3.35449C32.2846 3.30271 32.3534 3.30271 32.4059 3.33748C32.4407 3.38926 32.4407 3.45879 32.3882 3.59787L19.7844 31.0431C19.7326 31.1474 19.6809 31.1822 19.6291 31.1644C19.5936 31.1644 19.5418 31.0956 19.5418 31.0261L19.6283 17.329C19.6283 16.8955 19.473 16.6188 19.2991 16.445C19.1253 16.2719 18.8479 16.1158 18.4152 16.1158L4.71806 16.2024C4.64852 16.2024 4.57972 16.1506 4.57972 16.1158"
                    fill="#F3F4F5"
                  />
                </svg>
              </div>
              <div class="address-text">
                -3, 301, Platinum Techno Park, Sector 30, Vashi, Navi Mumbai,
                Maharashtra -400703
              </div>
            </div>
          </a>
        </div>
        <div v-if="isSmallScreen" class="row-two">
          <LinksSection />
        </div>
        <template v-else> <LinksSection /> </template>
      </div>
      <div class="invitation-section">
        <div class="invite-note">
          <div class="invite-note">Let's Connect</div>
          <div class="social-logos">
            <a
              :href="socialMedia.link"
              :key="key"
              v-for="(socialMedia, key) in socialMediaInfo"
              rel="noreferrer"
              target="_blank"
            >
              <img :src="socialMedia.path" :alt="`${socialMedia.name} icon`" />
            </a>
          </div>
        </div>
        <div class="copyright-note">
          Copyright © {{ currentYear }} Boomm. All rights reserved
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LinksSection from './LinksSection.vue'

export default {
  name: 'FooterSection',
  components: {
    LinksSection,
  },
  computed: {
    footerImagesPaths() {
      return {
        logo: require('@/assets/images/logos/secondary.svg'),
        facebook: require('@/assets/images//landingPage/footerSection/facebook-white.svg'),
        instagram: require('@/assets/images//landingPage/footerSection/instagram-white.svg'),
        x: require('@/assets/images//landingPage/footerSection/x-white.svg'),
        linkedin: require('@/assets/images//landingPage/footerSection/linkedin-white.svg'),
      }
    },
  },
  mounted() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth <= 992
    },
  },
  data() {
    return {
      isSmallScreen: false,
      currentYear: new Date().getFullYear(),
      socialMediaInfo: [
        {
          name: 'facebook',
          path: require('@/assets/images//landingPage/footerSection/facebook-white.svg'),
          link: 'https://www.facebook.com/share/D8hv5ZBmzjvXaX18/?mibextid=qi2Omg',
        },
        {
          name: 'instagram',
          path: require('@/assets/images//landingPage/footerSection/instagram-white.svg'),
          link: 'https://www.instagram.com/boommofficial?igsh=MXNkYjN0YWJjeGFwNQ%3D%3D&utm_source=qr',
        },
        {
          name: 'instagram',
          path: require('@/assets/images//landingPage/footerSection/x-white.svg'),
          link: 'https://x.com/boommOfficial?t=hJRED3JNxzzTmfCNlmNh7w&s=09',
        },
        {
          name: 'linkedin',
          path: require('@/assets/images//landingPage/footerSection/linkedin-white.svg'),
          link: 'https://www.linkedin.com/company/boommindia/',
        },
      ],
    }
  },
}
</script>

<style scoped>
@import '@/assets/css/footer.css';
</style>
