export const USER_DATA = {
    email: null,
    rank: null,
    referralId: null,
    fromWaitList: false
}

export const BLOGS = [
    {
        slug: 'stock-market-basics',
        title: 'Stock Market Basics',
        content: '/blogs/StockMarketBasics.html'
    },
    {
        slug: 'important-terms-in-stock-market',
        title: 'Important terms in Stock Market',
    content: '/blogs/StockMarketTerms.html'
    },
    {
        slug: 'financial-statements-basic-terms',
        title: 'Financial Statements - Basic terms',
        content: '/blogs/FinancialStatementTerms.html'
    },
    {
        slug: 'zero-to-one-investing-journey',
        title: 'Zero to One: Investing Journey',
        content: '/blogs/InvestingBook.html'
    },
]